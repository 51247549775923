.modal__body {
  width: 100%;
  max-height: 50vh;
  min-height: 50vh;
  margin-top: 30px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  .search {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .products__list {
    width: 100%;
    overflow-y: scroll;

    .empty {
      text-align: center;
      font-size: 16px;
      opacity: .7;
    }

    .product__list__item {
      width: 100%;

      .product__list__item__label {
        width: fit-content;
        display: flex;
        align-items: center;
        gap: 10px;

        .product__list__item__checkbox {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}
