.tab__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .tab__bar {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .tab {
      padding: 7px 20px;
      border: 1px solid #0252CC;
      border-right: none;
      transition: .1s linear;

      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-right: 1px solid #0252CC;
      }

      &:hover {
        background-color: #0252CC;
        color: #fff;
      }

      .tab__text {
        font-size: 12px;
      }
    }

    .tab__active {
      padding: 7px 20px;
      background-color: #0252CC;
      border: 1px solid #0252CC;
      border-right: none;
      transition: .1s linear;

      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-right: 1px solid #0252CC;
      }

      .tab__text {
        font-size: 12px;
        color: #fff;
      }
    }
  }

  .tab__content {
    width: 100%;

    .tab__content__card {
      position: relative;
      width: 100%;
      -webkit-box-shadow: 0 0 11px rgba(0, 0, 0, 0.02);
      box-shadow: 0 0 11px rgba(0, 0, 0, 0.02);
      background: #fff;
      padding: 10px;
      margin: 0 -10px;
      margin: 0 -0.625rem;

      .tab__content__card__loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 12px;
        z-index: 999;
        transition: .1s linear;
      }
    }

    @media (min-width: 992px) {
      .tab__content__card {
        border-radius: 12px;
        padding: 20px;
        margin: 0;
      }
    }
  }
}

