.main {
  width: 100%;
  display: flex;
  flex-direction: column;

  .list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.list__item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;

  .list__item__index {
    width: 13px;
  }

  .list__item__name {
    width: 150px;
  }

  .check {
    width: 20px;
    height: 20px;

    .loader__wrapper {
      width: 100%;
      height: 100%;
    }
  }
}

.dropdown {
  width: 100%;
  height: 300px;
  padding: 10px 0;
  background-color: #0252CC;
  animation: slideInDown 0.3s ease-in-out;
}

@keyframes slideInDown {
  from {
    height: 0;
  }

  to {
    height: 300px;
  }
}
