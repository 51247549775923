.main {
  width: 100%;
  padding: 30px;

  .container {
    width: 100%;
    height: 100%;

    .content__control {
      width: 100%;
      height: 35px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .add__button {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    .content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .feedback__page__skeleton {
        width: calc((100% - 10px) / 2);
        padding: 8px 16px;
        background-color: #FFFFFF;
        border: 1px solid rgb(153, 153, 153);
        border-radius: 5px;
      }

      .empty {
        width: 100%;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        font-size: 25px;
        font-weight: 600;
      }
    }
  }

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
