.card {
  width: 50%;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  .card__title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .row {
    width: 100%;
    display: flex;
    gap: 20px;

    .form__row {
      width: 100%;
      display: flex;
      align-items: flex-end;
      gap: 20px;
    }

    .form__column {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .tags {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .tag__reason {
      width: calc((100% - 10px) / 2);
      padding: 10px;
      border: 1px solid #E2E5ED;
      background-color: #FFFFFF;
      border-radius: 10px;

      .tag__reason__header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        .tag__reason__header__item {

        }
      }
    }

    .tag__add {
      width: calc((100% - 10px) / 2);
      border: 1px solid #E2E5ED;
      background-color: #FFFFFF;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: .1s linear;

      &:hover {
        border-color: #2a5aff;
        color: #2a5aff;
      }
    }
  }

  .marks {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .mark {
      width: calc((100% - 40px) / 5);
      padding: 10px;
      border: 1px solid #2a5aff;
      background-color: #FFFFFF;
      border-radius: 10px;

      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: .1s linear;

      color: #2a5aff;

      &:hover {
        background-color: #2a5aff;
        color: #FFFFFF;
      }
    }
  }

  .collapse__label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .collapse__label__alert {
      width: fit-content;
      padding: 0 10px;
      //border: 2px solid #ffeeba;
      color: #856404;
      border-radius: 5px;
      background-color: #fff3cd;
    }
  }

  .card__footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;

    .card__select__mark {
      width: calc((100% - 15px) / 2);
    }

    .card__add__mark {
      width: calc((100% - 15px) / 2);
    }
  }
}

.footer {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .card {
    width: 100%;
  }

  .footer {
    width: 100%;
  }
}
