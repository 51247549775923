.main {
  width: 100%;
  min-height: 100vh;
  padding: 60px 0 0 100px;

  .container {
    width: 100%;
    height: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .container__header {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      margin-bottom: 20px;

      .container__header__title {
        width: 100%;
        height: 32px;
        display: flex;
        align-items: center;

        font-size: 32px;
        color: rgb(25, 36, 52);
        text-transform: capitalize;
        font-weight: 600;
      }
    }
  }
}

@media (max-width: 992px) {
  .main {
    padding: 55px 0 0 0;
  }
}
