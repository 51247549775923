.reason {
  width: calc((100% - 10px) / 2);
  display: flex;
  flex-direction: column;
  border: 1px solid #E2E5ED;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 10px;

  .reason__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .reason__header__item {
      display: flex;
      align-items: center;
      gap: 10px;

      .reason__header__item__icon {
        cursor: pointer;
        transition: .1s linear;

        &:hover {
          color: #2a5aff;
        }
      }
    }
  }

  .reason__lang__item {
    width: 100%;
    margin-top: 10px;
  }
}
