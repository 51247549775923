.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-shadow: 0 0 11px rgba(0, 0, 0, 0.02);
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.02);
  background: #fff;
  gap: 5px;
  border-radius: 12px;

  .section__divider {
    width: 100%;
    height: 2px;
    background-color: #E5E5E5;
  }

  .block {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;

    .block__label {

    }

    .block__row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      border-top: 1px solid #E5E5E5;
      cursor: pointer;
      transition: .1s linear;

      &:last-child {
        border-bottom: 1px solid #E5E5E5;
      }

      .block__row__item {

        &:nth-child(1) {
          width: 10%;
        }

        &:nth-child(2) {
          width: 25%;
        }

        &:nth-child(3) {
          width: 15%;
        }

        &:nth-child(4) {
          width: 35%;
        }

        &:nth-child(5) {
          width: 15%;
        }

        .block__row__item__radio__btn {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          border: 2px solid #E5E5E5;
        }
      }

      &:hover {
        background-color: #F5F5F5;
      }
    }

    .block__params {
      width: 100%;
      display: flex;
      flex-direction: column;

      .block__params__item {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 10px 0;
        border-top: 1px solid #E5E5E5;

        &:last-child {
          border-bottom: 1px solid #E5E5E5;
        }

        .block__params__item__label {
          width: 50%;
          font-weight: 600;
        }

        .block__params__item__value {
          width: 50%;
        }
      }
    }

    .block__btns {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }
  }
}
