.coca__cola__settings {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 10px;

  .coca__cola__settings__label {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .coca__cola__settings__checkbox {
    width: 15px;
    height: 15px;
  }

  .coca__cola__settings__select {
    width: 100%;
  }

  .coca__cola__settings__btn {
    width: fit-content;
  }
}
