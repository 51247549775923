.feedback__page {
  width: calc((100% - 10px) / 2);
  padding: 8px 16px;
  background-color: #FFFFFF;
  border: 1px solid rgb(153, 153, 153);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  transition: .1s linear;

  .feedback__upper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .feedback__name {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;

      font-size: 16px;
      color: rgb(49, 49, 49);
      line-height: 24px;
      text-overflow: ellipsis;
    }

    .feedback__upper__group {
      display: flex;
      align-items: center;
      gap: 8px;

      .divider {
        width: 1px;
        height: 18px;
        background-color: rgb(153, 153, 153);
      }

      .feedback__delete {
        color: #0252CC;
        cursor: pointer;
        transition: .1s linear;

        &:hover {
          color: #023e99;
        }
      }
    }
  }

  .feedback__lower {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .feedback__slug {
      width: fit-content;
      overflow: hidden;
      white-space: nowrap;

      font-size: 14px;
      font-weight: 400;
      color: #2a5aff;
      line-height: 24px;
      text-overflow: ellipsis;

      transition: .1s linear;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 1200px) {
  .feedback__page {
    align-items: flex-start;

    .feedback__upper {
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;

      .feedback__name {

      }

      .feedback__upper__group {

        .divider {

        }

        .feedback__delete {

        }
      }
    }

    .feedback__lower {
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;

      .feedback__slug {

      }
    }
  }
}
