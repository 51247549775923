.card {
  width: 50%;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  .card__title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .card__body {
    width: 100%;
    display: flex;
    flex-direction: column;

    .card__body__empty {
      font-size: 16px;
      opacity: .7;
      margin-bottom: 20px;
    }

    .card__body__row {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      .card__body__row__title {
        margin: 0;
        display: flex;
        align-items: center;
        gap: 10px;

        font-size: 16px;
        font-weight: 600;

        .card__body__row__title__group {
          display: flex;
          align-items: center;
          gap: 10px;

          .card__body__row__title__icon {
            cursor: pointer;
            transition: .1s linear;

            &:hover {
              color: #2a5aff;
            }
          }
        }
      }

      .card__body__row__input {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
      }
    }
  }
}
