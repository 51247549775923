.reasons {
  width: 100%;
  padding-top: 30px;

  .reason__label {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    .reason__input {

    }
  }
}
