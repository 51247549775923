.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.card__content {
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .card {
    width: 250px;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 600px) {
    .card {
      width: 100%;
    }
  }
}

.period {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;

  .period__label {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 0;

    .period__input {
      border: 1px solid #e5e5e5;
      padding: 0 8px;
      border-radius: 8px;
    }
  }

  .period__btn {
    border-radius: 8px;
    padding: 0 10px;
    height: 26px;
  }
}

@media (max-width: 600px) {
  .period {
    flex-direction: column;

    .period__btn {
      width: 50%;
    }
  }
}
