.main {
  width: 100%;
  min-height: 100vh;
  padding: 60px 0 0 100px;
}

@media (max-width: 992px) {
  .main {
    padding: 60px 0 0 0;
  }
}
