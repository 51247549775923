.tagging__service {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;

  .tagging__service__label {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    white-space: nowrap;
  }
}
