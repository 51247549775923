.main {
  width: 100%;
  display: flex;
  flex-direction: column;

  .main__empty {
    text-align: center;
    margin: 50px 0;
  }

  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1.5px solid #e5e5e5;
    margin-top: 20px;
    padding: 10px;
    border-radius: 10px;

    .list__title {

    }

    .list__container {
      width: 100%;
      display: flex;
      gap: 20px;

      .list__container__block {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .list__block__row {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 6px;

          .list__block__row__title {
            font-size: 14px;
            font-weight: 500;
          }

          .list__block__row__input {
            width: 100%;
            min-height: 30px;
            height: fit-content;
            border: 1px solid #b0b0b0;
            border-radius: 4px;
            padding: 4px 8px;
            display: flex;
            flex-wrap: wrap;
            gap: 5px;

            .list__block__row__input__item {
              padding: 0 5px;
              background-color: #e5e5e5;
              border: 1px solid #b0b0b0;
              border-radius: 4px;
              display: flex;
              align-items: center;
              gap: 5px;
              cursor: pointer;
              transition: .1s linear;

              &:hover {
                background-color: #d5d5d5;
              }
            }
          }
        }

        .list__container__block__row {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 10px;

          .list__container__block__row__item {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 5px 10px;
            border: 1px solid #b0b0b0;
            border-radius: 4px;


            .list__container__block__row__item__upper {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .list__container__block__row__item__upper__text {

              }

              .list__container__block__row__item__upper__delete {
                font-size: 16px;
                cursor: pointer;
                transition: .1s linear;

                &:hover {
                  color: red;
                  transform: scale(1.1);
                }

                &:active {
                  transform: scale(1);
                }
              }
            }

            .list__container__block__row__item__lower {

            }
          }
        }
      }

      .list__container__divider {
        width: 1px;
        background-color: #e2e3e4;
      }
    }
  }
}
