.breadcrumbs__bar {
  width: 100%;
  height: 19.6px;
  display: flex;
  align-items: flex-start;
  gap: 3px;

  .breadcrumb {
    display: flex;
    align-items: center;
    transition: .1s linear;

    .breadcrumb__text {
      line-height: 14px;
      font-size: 12px;
      color: #0252CC;
      text-transform: uppercase;
    }
  }

  .breadcrumb__divider {
    font-size: 10px;
    color: #8392A5;
  }
}
