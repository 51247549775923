.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


/* .modal-dialog-fullscreen { */
/*     margin: 0; */
/*     top: 0; */
/*     bottom: 0; */
/*     left: 0; */
/*     right: 0; */
/*     height: 100vh; */
/*     max-height: 100vh; */
/*     display: flex; */
/*     width: 100%; */
/* } */

.logo__img {
    max-width: 5rem;
}

.modal-dialog-fullscreen {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 20px;
    max-width: none !important;
}

.modal-dialog-fullscreen > .modal-content {
    height: auto;
    min-height: 100%;
}

#cropper-div {
    max-height: 80vh;
}

@media (min-width: 768px) {
    .uploader.-big.-slider {
        /* To have slider aspect ratio of 2.2 */
        width: 22rem;
        height: 10rem;
    }

    .uploader.-big.-section {
        /* To have section aspect ratio of 16/9 */
        width: 18rem;
        height: 10rem;
    }

    .uploader.-big.-item {
        /* To have image aspect ratio of 16/9 */
        width: 18rem;
        height: 10rem;
    }
}

@media (max-width: 575.98px) {
    .uploader.-big.-item {
        width: 100%;
        height: calc(100vw * 0.5625); /* 16.9 */
    }

    .uploader.-big.-slider {
        width: 100%;
        height: calc(100vw * 0.4545);
    }

}

.uploader__area.-has-file {
    border: none;
}

@media (min-width: 992px) {
    .catalog__text:first-child {
        border-radius: 0.375rem 0 0 0.375rem;
    }
}

.app__header-right {
    margin-left: auto !important;
}

.app__header-right .button {
    margin-left: 1rem;
}

@media (min-width: 992px) {
    .catalog__draggable-area {
        cursor: grab;
    }
}

.control__textarea.small-textarea {
    min-height: 60px;
}

.tags__item {
    margin-right: 5px;
}

@media (min-width: 768px) {
    .modal-preview__img {
        width: 18rem;
        height: 10rem;
        max-width: none;
        max-height: none;
    }
}

@media (max-width: 575.98px) {
    .modal-preview__img {
        width: 100%;
        height: calc(100vw * 0.5625); /* 16.9 */
        max-width: none;
        max-height: none;
    }
}

.unset {
    color: #808080;
    font-style: italic;
}


.paginator__item.ellipsis .paginator__link {
    background: none;
    pointer-events: none;
    cursor: auto;
}

.spinner-in-btn {
    margin-right: 5px;
}

.catalog__img.slide {
    max-width: 4.36rem;
}

tfoot {
    border-top: 2px solid #ccc;
    font-weight: bold;
}

.content-preloader {
    height: 30vh;
}

.content-preloader.preloader:before {
    background: none;
}

.min-height-table {
    min-height: 20vh;
}

/**===== bars3 =====*/
.bars3 {
    display: block;
    width: 80px;
    margin-top: -3px;
}

/* .bars3 span { */
/*   position: absolute; */
/*   display: block; */
/*   bottom: 10px; */
/*   width: 9px; */
/*   height: 15px; */
/*   background: rgba(0, 0, 0, 0.25); */
/*   -webkit-animation: bars3 2s  infinite ease-in; */
/*           animation: bars3 2s  infinite ease-in; */
/* } */

.bars3 span {
    display: inline-block;
    width: 9px;
    height: 5px;
    background: rgba(0, 0, 0, 0.15);
    margin-right: 3px;
    -webkit-animation: bars3 .5s infinite ease-in;
    animation: bars3 .5s infinite ease-in;
}

.bars3 span:nth-child(2) {
    left: 11px;
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}

.bars3 span:nth-child(3) {
    left: 22px;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.bars3 span:nth-child(4) {
    left: 33px;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

.bars3 span:nth-child(5) {
    left: 44px;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

@keyframes bars3 {
    0% {
        background: rgba(0, 0, 0, 0.1);
    }
    25% {
        background: #000000;
    }
    50% {
        background: rgba(0, 0, 0, 0.1);
    }
    100% {
        background: rgba(0, 0, 0, 0.1);
    }
}

@-webkit-keyframes bars3 {
    0% {
        background: rgba(0, 0, 0, 0.1);
    }
    25% {
        background: #000000;
    }
    50% {
        background: rgba(0, 0, 0, 0.1);
    }
    100% {
        background: rgba(0, 0, 0, 0.1);
    }
}


.publish-button {
    /* position: fixed; */
    width: 100%;
    height: 3.5rem;
    /* background-color: #ffcb00; */
    background-color: #ffcb009e;
    color: #000;
    /* top: 0px; */
}

.publish-buton__link {
    height: 2rem;
}

.publish-panel {
    position: fixed;
    /* top: -3.5rem; */
    bottom: 0;
    width: 100%;
    -webkit-transition: top .2s linear;
    transition: top .2s linear;
    z-index: 1001;
}


.app__main {
    min-height: 70vh;
    margin-bottom: 5rem;
}

.uploader.-logo {
    width: 100%;
    height: 8rem;
}

.uploader.-logo .uploader__img {
    object-fit: contain;
}

.uploader.-icon {
    max-height: 96px;
}

.disabled-segment {
    position: relative;
    pointer-events: none;
}

.disabled-segment:before {
    content: '';
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
    z-index: 9;
}

.required-star {
    color: crimson;
    margin-left: 0.2rem;
}

/**===== toasts =====*/
#toasts {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.km-toast {
    position: relative;
    padding: 0.75rem 1rem;
    margin: 1rem 1rem 0;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-radius: 0.25rem;
    animation: slideIn .2s;
    -webkit-animation: slideIn .2s;
    -moz-animation: slideIn .2s;
}

@media (min-width: 992px) {
    .km-toast {
        width: 20rem;
    }
}

@media (min-width: 640px) {
    .km-toast {
        margin-left: auto;
        margin-right: auto;
    }
}

.km-toast__body {
    display: flex;
}

.km-toast__title {
    font-weight: bold;
    margin: 0;
}

.km-toast__message {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin: 0;
}

.km-toast__close {
    position: absolute;
    margin-left: auto;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    color: rgba(75, 85, 99, 1);
}

.km-toast__close:hover {
    color: rgba(17, 24, 39, 1);
}

@media (min-width: 640px) {
    .km-toast__close {
        position: relative;
        top: auto;
        right: auto;
    }
}

.km-toast__close svg {
    width: 1rem;
    height: 1rem;
}

.km-toast--success {
    background-color: rgba(209, 250, 229, 1);
    border-color: rgba(16, 185, 129, 1);
    color: rgba(5, 150, 105, 1);
}

.km-toast--error {
    background-color: rgba(254, 202, 202, 1);
    border-color: rgba(239, 68, 68, 1);
    color: rgba(220, 38, 38, 1);
}

.km-toast--warning {
    background-color: rgba(253, 230, 138, 1);
    border-color: rgba(245, 158, 11, 1);
    color: rgba(217, 119, 6, 1);
}

@keyframes slideIn {
    from {
        opacity: 0;
        top: -20px;
    }
    to {
        opacity: 1;
        top: 0px;
    }
}

@-webkit-keyframes slideIn {
    from {
        opacity: 0;
        top: -20px;
    }
    to {
        opacity: 1;
        top: 0px;
    }
}

@-moz-keyframes slideIn {
    from {
        opacity: 0;
        top: -20px;
    }
    to {
        opacity: 1;
        top: 0px;
    }
}


@keyframes slideOut {
    from {
        opacity: 1;
        top: 0px;
    }
    to {
        opacity: 0;
        top: -20px;
    }
}

@-webkit-keyframes slideOut {
    from {
        opacity: 1;
        top: 0px;
    }
    to {
        opacity: 0;
        top: -20px;
    }
}

@-moz-keyframes slideOut {
    from {
        opacity: 1;
        top: 0px;
    }
    to {
        opacity: 0;
        top: -20px;
    }
}

.number-td {
    text-align: right;
}

/* overwrite! */
.search {
    width: 100%;
}

.default-card__top-row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.orders-table tr {
    background-color: #ffffff;
    transition: background-color 2s linear;
}

tr.unread-row {
    background-color: #ffeeba;
    transition: background-color 1s linear;
}


/* overwrite! */
@media (min-width: 992px) {
    .logo__img img {
        height: auto;
    }
}

.logo__img img {
    height: auto;
}

tr.archived-user {
    opacity: .35;
}

.shadow-user {
    display: block;
    font-weight: bold;
    color: #6c757d !important;
}

@media (min-width: 992px) {
    .sidebar__header {
        height: auto;
        min-height: 60px;
    }
}

.sidebar__other-row {
    display: flex;
    padding: 0.9375rem;
}

@media (min-width: 992px) {
    .lg-text-right {
        text-align: right;
    }
}

/*
  Analytics, dashboard etc
*/
.dashboard-number {
    font-size: 1.2rem;
    color: #343a40 !important;
    font-weight: 700 !important;
}

.dashboard-subtext {
    color: #6c757d !important;
    text-transform: uppercase !important;
}

.catalog__text.hidden-item {
    opacity: .5;
}

tr.hidden-item {
    opacity: .5;
}

span.disabled-item-hint {
    color: #FF4136 !important;
    font-weight: bold !important;
}

span.hidden-item-hint {
    color: #888 !important;
    font-weight: bold !important;
}

.price-in-menu-row {
    margin: 10px;
    white-space: nowrap;
}

@media (max-width: 991.98px) {
    .default-table td:before {
        text-align: left;
    }
}

.row-tuple {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.row-tuple__price {
    flex: 0 0 auto;
}

.modifier-tuple {
    font-size: 13px;
}

.left-side-panel {
    border-right: 1px solid #eeeeee;
}

.mod-item {
    border-bottom: 1px dashed #cccccc;
    margin-bottom: 1rem;
}

.hidden-w-opacity {
    opacity: .5;
}

@media (max-width: 575.98px) {
    .mod-item {
        border-top: 1px dashed #cccccc;
        border-bottom: 1px dashed #cccccc;
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
        margin-bottom: 0;
    }

    .mod-item .form__remove-button {
        margin-top: 0;
    }
}

.row.mod-select {
    margin-top: 1rem;
}

.row.add-price-tuple {
    border-top: 1px dashed #cccccc;
    padding-top: 1rem;
}

.order-item-row__main-item {
    font-size: 16px;
}

.order-item-row__modifier {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    margin-bottom: 5px;
}

.order-item-row__modifier .control {
}

.order-item-row__modifier input {
    width: 70px;
}

.control__input.small-input {
    line-height: 1;
    padding: 4px 5px;
    height: 30px;
}

textarea.code {
    font-family: monospace;
    font-size: 12px;
}


/* Proper Loader for blocks */

.lds-roller-wrapper {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lds-roller-error {
    transition: .1s linear;
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    transition: .1s linear;
}

.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fc0;
    margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
