.rating__bar__chart {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .rating__bar__chart__item {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;

    .rating__bar__chart__item__title {
      width: 10px;

      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: rgb(25, 36, 52);
    }

    .rating__bar__chart__item__bar {
      width: 100%;
      height: 8px;
      background-color: rgb(239, 239, 239);
      border-radius: 4px;
      position: relative;

      .rating__bar__chart__item__bar__progress {
        width: 0;
        height: 100%;
        background-color: rgb(255, 107, 107);
        border-radius: 4px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .rating__bar__chart__item__rate {
      width: 30px;

      white-space: nowrap;
      text-align: end;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: rgb(25, 36, 52);
    }
  }
}
