.link__item {
  width: 100%;
  border-bottom: 1px solid #E2E5ED;
  padding-top: 10px;

  &:first-child {
    border-top: 1px solid #E2E5ED;
  }

  .link__item__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .link__item__header__index {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .link__item__header__delete {
      font-size: 18px;
      transition: .1s linear;

      &:hover {
        color: #2a5aff;
        transform: scale(1.1);
      }
    }
  }

  .link__label {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    color: #8392A5;

    >div {
      width: 100%;
      color: #000000;
    }
  }

  .image {
    position: relative;
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 2px dashed #adadad;
    background-color: rgba(226, 229, 237, 0.5);
    cursor: pointer;
    transition: .1s linear;

    &:hover {
      background-color: rgba(226, 229, 237, 0.7);
      border-color: #4096ff;
      color: #4096ff;
    }

    > div {
      text-align: center;
    }

    .image__img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 10px;
    }

    .image__delete {
      position: absolute;
      top: 3px;
      right: 3px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 18px;
      color: #4096ff;
      background-color: #FFFFFF;
      cursor: pointer;
      transition: .1s linear;

      &:hover {
        background-color: #4096ff;
        color: #FFFFFF;
      }
    }
  }
}
