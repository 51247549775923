.main {
  width: 100%;
  height: 100vh;
  padding: 60px 0 0 100px;

  .container {
    width: 100%;
    height: 100%;
    padding: 30px;

    .card {
      width: 100%;
      height: calc(100% - 82px);
      background-color: #FFFFFF;
      border-radius: 10px;
      padding: 10px;

      .table {
        width: 100%;
        height: 100%;
        border: 1px solid #E5E5E5;
        border-radius: 10px;
        display: flex;

        .table__column {
          width: calc(100% / 3);
          height: 100%;
          border-right: 1px solid #E5E5E5;
          display: flex;
          flex-direction: column;
          align-items: center;

          &:last-child {
            border-right: none;
          }

          .table__column__header {
            width: 100%;
            min-height: 50px;
            max-height: 50px;
            border-bottom: 1px solid #E5E5E5;
            display: flex;
            align-items: center;
            justify-content: center;

            font-size: 18px;
            font-weight: 600;
            color: rgb(25, 36, 52);
            text-transform: uppercase;
          }

          .table__column__searchbar__input {
            width: calc(100% - 20px);
            height: 40px;
            border: 1px solid #E5E5E5;
            border-radius: 20px;
            margin: 10px;
            padding: 0 15px;
          }

          .table__column__products {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: auto;
            border-top: 1px solid #E5E5E5;

            .product__card {
              width: 100%;
              border-top: 1px solid #E5E5E5;
              border-bottom: 1px solid #E5E5E5;
              display: flex;
              flex-direction: column;
              align-items: center;
              cursor: pointer;

              .product__card__title {
                font-size: 16px;
              }

              .product {
                border-bottom: none;
              }
            }

            .product__card__icon {
              font-size: 30px;
            }

            .product {
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 5px;
              padding: 10px;
              border-bottom: 1px solid #E5E5E5;
              cursor: pointer;
              transition: .1s linear;

              &:last-child {
                border-bottom: none;
              }

              .product__title {
                font-size: 16px;
                font-weight: 600;
              }

              .product__price {
                font-size: 16px;
                font-weight: 400;
              }

              .product__id {
                font-size: 14px;
                font-weight: 300;
                opacity: .6;
              }
            }

            .empty__list {
              width: 100%;
              height: 95px;
              display: flex;
              align-items: center;
              justify-content: center;

              text-transform: capitalize;
              font-size: 18px;
              font-weight: 400;
              color: rgb(25, 36, 52);
              opacity: .6;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .main {
    padding: 55px 0 0 0;
  }
}
