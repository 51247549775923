.main {
  width: 100%;
  display: flex;
  flex-direction: column;

  .row {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .row__label {
      width: 150px;
      font-size: 16px;
      font-weight: 600;
      line-height: 21px;
    }

    .row__value {
      width: calc(100% - 150px - 20px);
      min-height: 30px;
      height: fit-content;
      border: 1px solid #b0b0b0;
      border-radius: 4px;
      padding: 4px 8px;
      display: flex;
      flex-wrap: wrap;
      gap: 5px;

      .row__value__item {
        padding: 0 5px;
        background-color: #e5e5e5;
        border: 1px solid #b0b0b0;
        border-radius: 4px;
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;

        .row__value__item__text {

        }

        .row__value__item__remove__icon {
          transition: .1s linear;

          &:hover {
            color: #ff0000;
          }
        }
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #e5e5e5;
    margin-bottom: 20px;
  }
}
