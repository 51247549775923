.main {
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;

  .container {
    width: 100%;
    display: flex;
    justify-content: center;

    .container__inner {
      max-width: 1200px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .rating__profile {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-bottom: 30px;

        .rating__profile__left {
          font-size: 60px;
          font-weight: 600;
          color: rgb(25, 36, 52);
          line-height: 60px;
        }

        .rating__profile__right {
          height: 60px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .rating__profile__right__name {
            font-size: 25px;
            font-weight: 600;
            color: rgb(25, 36, 52);
            line-height: 30px;
            text-transform: uppercase;
          }

          .rating__profile__right__rate {

          }
        }
      }

      .divider__title {
        width: 100%;
        font-size: 25px;
        margin: 0;
      }

      .feedbacks__filter {
        display: flex;
        align-items: center;
        gap: 20px;

        .feedbacks__filter__label {
          font-size: 16px;
          font-weight: 500;
          color: rgb(25, 36, 52);
          line-height: 24px;
        }
      }

      .stats {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 50px;
        margin-bottom: 50px;

        .stats__numbers {
          display: flex;
          justify-content: space-evenly;
          gap: 20px;

          .stats__numbers__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;

            .stats__numbers__item__value {
              font-size: 40px;
              font-weight: 600;
              color: rgb(25, 36, 52);
              line-height: 36px;
            }

            .stats__numbers__item__title {
              font-size: 16px;
              font-weight: 500;
              color: rgb(25, 36, 52);
              line-height: 24px;
            }
          }

          .stats__numbers__item__divider {
            width: 2px;
            background-color: rgb(153, 153, 153);
          }
        }

        .stats__reasons {
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          gap: 20px;

          .stats__reasons__empty {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            text-transform: capitalize;
            font-size: 18px;
            font-weight: 600;
            color: rgb(25, 36, 52);
            line-height: 24px;
          }

          .stats__reasons__item {
            width: calc((100% - 40px) / 5);
            display: flex;
            flex-direction: column;
            gap: 10px;
            background-color: #FFFFFF;
            padding: 20px;
            border-radius: 12px;
            box-shadow: rgba(0, 0, 0, 0.02) 0 0 11px 0;

            .stats__reasons__item__name {
              width: 100%;
              display: flex;
              flex-direction: column;

              .stats__reasons__item__name__lang {
                width: 100%;
                display: flex;
                gap: 10px;

                .stats__reasons__item__name__lang__value {
                  font-size: 14px;
                  font-weight: 600;
                  color: rgb(25, 36, 52);
                  line-height: 24px;
                }
              }
            }
          }
        }

        .stats__block {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;
          background-color: #FFFFFF;
          padding: 20px;
          border-radius: 12px;
          box-shadow: rgba(0, 0, 0, 0.02) 0 0 11px 0;

          .stats__block__title {
            margin-bottom: 0;
          }
        }
      }

      .feedbacks {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 50px;

        .feedbacks__title {
          width: 100%;
        }

        .feedbacks__list {
          max-width: 1200px;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 10px;

          .feedbacks__list__empty {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            font-size: 18px;
            font-weight: 600;
            color: rgb(25, 36, 52);
            line-height: 24px;
            text-transform: capitalize;
          }

          .feedback__list__item {
            width: 100%;
            display: flex;
            gap: 10px;
            background-color: #FFFFFF;
            padding: 8px 16px;
            border: 2px solid rgb(153, 153, 153);
            border-radius: 5px;

            .feedback__list__item__left {
              width: 30%;
              display: flex;
              flex-direction: column;

              .feedback__list__item__left__name {
                width: 100%;

                font-size: 16px;
                font-weight: 600;
                color: rgb(49, 49, 49);
                line-height: 24px;
              }

              .feedback__list__item__left__phone {
                width: 100%;

                font-size: 14px;
                font-weight: 400;
                color: rgb(49, 49, 49);
                line-height: 24px;

                margin-bottom: 30px;
              }
            }

            .feedback__list__item__right {
              width: 70%;
              display: flex;
              flex-direction: column;
              gap: 15px;

              .feedback__list__item__right__header {
                width: 100%;
                display: flex;
                justify-content: space-between;

                .feedback__list__item__right__rating__bar {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  padding: 2px 0;

                  .feedback__list__item__right__rating__bar__item {
                    font-size: 24px;
                  }
                }

                .feedback__list__item__left__submitted__at {
                  white-space: nowrap;
                  font-size: 12px;
                  font-weight: 400;
                  color: rgb(49, 49, 49);
                  line-height: 24px;
                }
              }

              .feedback__list__item__right__reasons {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                gap: 10px;

                .feedback__list__item__right__reasons__item {
                  width: fit-content;
                  padding: 2px 10px;
                  border-radius: 5px;
                  background-color: #e2e3e4;

                  font-size: 14px;
                  font-weight: 400;
                  color: rgb(49, 49, 49);
                  line-height: 24px;
                }
              }

              .feedback__list__item__right__comment {

              }

              .feedback__list__item__right__clicked__links {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .feedback__list__item__right__clicked__links__title {
                  font-size: 14px;
                  font-weight: 600;
                  color: rgb(49, 49, 49);
                  line-height: 24px;

                  .feedback__list__item__right__clicked__links__item__url {

                  }
                }
              }
            }
          }
        }

        .feedbacks__list__pagination {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
