.button {
  outline: none;

  padding: 10px 20px;
  background-color: #0252cc;
  border: 1px solid #0252cc;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  color: #FFFFFF;
  line-height: 20px;
  transition: .1s linear;

  &:hover {
    background-color: #023e99 !important;
    border: 1px solid #023e99 !important;
    color: #FFFFFF !important;
  }

  &:active {
    background-color: #0252cc !important;
    border: 1px solid #0252cc !important;
    outline: none;
  }

  &:focus {
    outline: none;
  }

  .btn__loading {
    font-size: 20px;
    transition: .1s linear;
  }
}

.button__delete {
  outline: none;

  padding: 10px 20px;
  background-color: #ff4136;
  border: 1px solid #ff4136;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  color: #FFFFFF;
  line-height: 20px;
  transition: .1s linear;

  &:hover {
    background-color: #d9382f !important;
    border: 1px solid #d9382f !important;
    color: #FFFFFF !important;
  }

  &:active {
    background-color: #ff4136 !important;
    border: 1px solid #ff4136 !important;
    outline: none;
  }

  &:focus {
    outline: none;
  }

  .btn__loading {
    font-size: 20px;
    transition: .1s linear;
  }
}
