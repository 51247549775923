.tree {
  width: 100%;

  .tree__section__container {
    width: 100%;

    .tree__section {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 20px;
      background-color: white;
      border-radius: 6px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      .tree__section__name {
        font-weight: 400;
      }
    }

    .tree__category__container {
      width: calc(100% - 45px);
      margin-left: 45px;

      .tree__category {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 20px;
        background-color: white;
        border-radius: 6px;
        margin-bottom: 10px;

        .tree__category__name {
          font-weight: 400;
        }
      }

      .tree__item__container {
        width: calc(100% - 45px);
        margin-left: 45px;

        .tree__item {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px 20px;
          background-color: white;
          border-radius: 6px;
          margin-bottom: 10px;

          .tree__item__name {
            font-weight: 400;
          }
        }
      }
    }

    .tree__mention__container {
      width: calc(100% - 45px);
      margin-left: 45px;

      .tree__mention {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 20px;
        background-color: #2a5aff;
        color: #FFFFFF;
        border-radius: 6px;
        margin-bottom: 10px;

        .tree__mention__name {
          font-weight: 400;
        }
      }
    }

    .tree__icon__group {
      display: flex;
      align-items: center;
      justify-content: center;

      .tree__icon__group__item {
        font-size: 18px;
        cursor: pointer;
        transition: .1s linear;

        &:hover {
          color: red;
        }
      }
    }
  }
}
