.form__column {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .reasons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .reasons__add {
      width: calc((100% - 10px) / 2);
      padding: 10px;
      border: 1px solid #E2E5ED;
      background-color: #FFFFFF;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: .1s linear;

      &:hover {
        border-color: #2a5aff;
        color: #2a5aff;
      }
    }
  }

  .link_column {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
