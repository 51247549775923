.main {
  width: 100%;
  min-height: 100vh;
  padding: 60px 0 0 100px;

  .container {
    width: 100%;
    padding: 30px;
  }
}
